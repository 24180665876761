import React from "react"
import { graphql } from "gatsby"
import PageLayout from "./page-layout"
import BlogCard from "../components/blog-card"
import Wrapper from "../components/wrapper"

export default function BlogLayout({
  data: {
    markdownRemark: {
      frontmatter: { bg, title, opacity },
    },
    allMarkdownRemark,
  },
  pageContext: { lang },
}) {
  const breadcrumbs =
    lang === "ar"
      ? [{ title: title, path: "/blog" }]
      : [
          {
            title: title,
            path: "/en/blog",
          },
        ]

  return (
    <PageLayout
      breadcrumbsPaths={breadcrumbs}
      maxHeight={287}
      opacity={opacity}
      mainImage={bg?.childImageSharp?.fluid || {}}
      title={title}
    >
      <Wrapper style={{ mt: 5 }}>
        {allMarkdownRemark.edges?.map(({ node }) => (
          <BlogCard
            {...node.frontmatter}
            to={node.fields.slug}
            sx={{ mb: 4 }}
            key={node.id}
          />
        ))}
      </Wrapper>
    </PageLayout>
  )
}

export const blogQuery = graphql`
  query($limit: Int!, $skip: Int!, $lang: String!) {
    markdownRemark(
      frontmatter: { lang: { eq: $lang } }
      fileAbsolutePath: { regex: "/pages/blog/" }
    ) {
      frontmatter {
        title
        opacity
        bg {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { lang: { eq: $lang } }
        fileAbsolutePath: { regex: "/blog//*/" }
      }
      sort: { fields: frontmatter___date, order: DESC }

      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "YYYY. MM. DD")
            author
            category
            img {
              childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
