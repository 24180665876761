import React from "react"
import { Flex, Box } from "theme-ui"
import { Link } from "gatsby"
import useLanguage from "../hooks/useLanguage"

export default function BlogRelatedData({ date, author, category }) {
  const pathPrefix = useLanguage() === "ar" ? "" : "/en"

  return (
    <Flex
      sx={{
        div: {
          borderBottom: "1px dashed #e5e5e5",
          py: 10,
          marginInlineStart: 10,
        },
      }}
    >
      <Box dir="ltr">{date}</Box>
      <Link
        style={{ textDecoration: "none" }}
        to={`${pathPrefix}/authors/${author}/`}
      >
        <Box sx={{ color: "text" }}>{author}</Box>
      </Link>
      <Link
        style={{ textDecoration: "none" }}
        to={`${pathPrefix}/categories/${category}/`}
      >
        <Box sx={{ color: "text" }}>{category}</Box>
      </Link>
    </Flex>
  )
}
