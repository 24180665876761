import React from "react"
import { Card, Styled, Box, Link } from "theme-ui"
import Image from "gatsby-image"
import { Link as GLink } from "gatsby"
import Icon from "react-icons-kit"
import { arrows_slim_left } from "react-icons-kit/linea/arrows_slim_left"
import { arrows_slim_right } from "react-icons-kit/linea/arrows_slim_right"
import { arrows_plus } from "react-icons-kit/linea/arrows_plus"
import BlogRelatedData from "./blog-related-data"
import useLanguage from "../hooks/useLanguage"

export default function BlogCard({
  description,
  title,
  date,
  author,
  img,
  category,
  sx,
  to,
}) {
  const blogRelatedData = {
    date,
    author,
    category,
  }
  const isAR = useLanguage() === "ar"

  return (
    <Card sx={sx}>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box as={GLink} to={to} sx={{}}>
          <Image
            style={{
              maxHeight: 350,
              overflow: "hidden",
            }}
            imgStyle={{
              objectFit: "contain",
            }}
            fluid={img?.childImageSharp?.fluid}
          />
        </Box>

        <Box
          as={GLink}
          to={to}
          sx={{
            display: "flex",
            position: "absolute",
            inset: 0,

            fontSize: 50,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(51, 51, 51, 0.3)",
            color: "#fff",
            transition: "0.3s all ease-in-out",
            fontWeight: "200",
            opacity: 0,
            zIndex: 2,
            ":hover": {
              opacity: 1,
            },
          }}
        >
          <Icon size={80} icon={arrows_plus} />
        </Box>
      </Box>
      <Box sx={{ p: 4 }}>
        <BlogRelatedData {...blogRelatedData} />
        <Styled.h2
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            borderBottom: "1px dashed #e5e5e5",
          }}
        >
          <Link
            sx={{
              color: "text",

              ":hover": { color: "primary", textDecoration: "none" },
            }}
            as={GLink}
            to={to}
          >
            {title}
          </Link>
        </Styled.h2>
        <Styled.p>{description}</Styled.p>
        <Link
          to={to}
          sx={{
            color: "text",
            ":hover": { color: "primary", paddingInlineStart: 10 },
          }}
          as={GLink}
        >
          <span style={{ display: "inline-block", marginInlineEnd: 10 }}>
            {isAR ? "قراءة المزيد" : "Read me"}
          </span>
          <Icon size={24} icon={isAR ? arrows_slim_left : arrows_slim_right} />
        </Link>
      </Box>
    </Card>
  )
}
